import React from "react"
import SitePreview from "../img/live-ml-course.png"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import loadable from "@loadable/component"
const Footer = loadable(() => import("../components/footer3"))
const MlCourseForm = loadable(() => import("../components/MlCourseForm"))
const MLFaq = loadable(() => import("../components/mlCourseFaq"))
const SocialMedia = loadable(() => import("../components/social-media"))

export default class PersonalizedMLCourse extends React.Component {
  render() {
    return (
      <div className="">
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title> 16-Week Live ML Course </title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            property="og:description"
            content="A guided live course for beginners or intermediate learners to learn machine learning from scratch and prepare for ML interviews."
          />
          <meta name="image" property="og:image" content={SitePreview} />
          <meta
            name="title"
            property="og:title"
            content="16 weeks live ML course"
          />
        </Helmet>
        <div className="">
          <NavigationBar />
        </div>

        <div className="bg-white">
          <div className="container mx-auto overflow-hidden">
            <div className="pt-5 md:pt-6 xl:pt-7 2xl:pt-10 px-5 sm:px-6 lg:px-8 xl:px-10">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="lg:col-span-7 tracking-wider text-center md:text-left">
                  <h2 className="text-2xl lg:text-3xl 2xl:text-4xl text-left text-gray-900 mb-4">
                    16-Week Live ML Course
                  </h2>
                  <p className="text-base xl:text-lg 2xl:text-xl text-gray-600 text-left mb-4 mr-0 md:mr-4">
                    A personalized course designed for beginners and
                    intermediate learners eager to delve into the world of data
                    and machine learning. Our goal is to guide you in mastering
                    ML algorithms, enabling you to formulate solutions for
                    industry problems, equipping you with the skills needed to
                    excel in interviews, and boosting your confidence as a
                    problem solver.
                  </p>
                  <div className="flex justify-center">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "56.25%",
                      }}
                    >
                      <img
                        src={SitePreview}
                        alt="Live machine learning course"
                        className="mt-3 object-cover pointer-events-none absolute top-0"
                      />
                    </div>
                  </div>
                </div>
                <div className="tracking-wider lg:col-span-5 mt-12 lg:mt-4">
                  <div className="bg-white border-2 p-5 sm:p-6 lg:p-5 xl:p-6 2xl:p-10 shadow-md rounded-2xl">
                    <h3 className="text-lg lg:text-xl xl:text-2xl text-gray-900 mb-2">
                      Course Details
                    </h3>
                    <div className="mt-2 xl:mt-4 text-gray-600 text-sm sm:text-base lg:text-sm xl:text-base 2xl:text-lg">
                      <p className="mb-3 xl:mb-5">
                        2 classes/week, 32 live classes, 64+ hours guided
                        learning, 8 learners in a batch.
                      </p>
                      <p className="mb-3 xl:mb-5">
                        Tuition fee (India): ₹30,000, Tuition fee (Other
                        countries): $700 (Inclusive all taxes)
                      </p>
                      <div className="flex justify-left">
                        <button className="w-60 sm:w-96 lg:w-72 xl:w-80 mt-1 border border-new-green shadow bg-white text-new-green hover:bg-new-green hover:text-white rounded transition ease-in-out duration-150">
                          <a
                            href="https://res.cloudinary.com/dzdszmwcp/image/upload/v1706278288/enjoyalgorithms-machine-learning-curriculum_zhumkt.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex justify-center"
                          >
                            <p className="tracking-wider flex px-3 sm:px-5 text-center py-2 text-base sm:text-lg md:text-base lg:text-lg 2xl:text-xl">
                              Explore Curriculum
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-arrow-right ml-1"
                              >
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <polyline points="12 5 19 12 12 19"></polyline>
                              </svg>
                            </p>
                          </a>
                        </button>
                      </div>

                      <p className="mt-4 lg:mt-3 xl:mt-4 mb-3 lg:mb-2 xl:mb-4">
                        Our current batch enrollments are closed. To receive
                        notifications about our upcoming batches, you can join the
                        waitlist using your email.
                      </p>

                      <div className="flex justify-left">
                        <button className=" w-60 sm:w-96 lg:w-72 xl:w-80 mt-1 border border-new-purple shadow bg-white text-new-purple hover:bg-new-purple hover:text-white rounded transition ease-in-out duration-150">
                          <a
                            href="https://forms.gle/asDvv9mdJFRKkrRu7"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex justify-center"
                          >
                            <p className="flex tracking-wider px-3 sm:px-5 py-2 text-center text-base sm:text-lg md:text-base lg:text-lg 2xl:text-xl">
                              Join the Waitlist
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-arrow-right ml-1"
                              >
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <polyline points="12 5 19 12 12 19"></polyline>
                              </svg>
                            </p>
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ul className="mt-8 xl:mt-12 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-12">
                <div className="tracking-wider border-2 text-sm sm:text-base 2xl:text-lg text-gray-600 p-4 sm:p-6 rounded-2xl shadow-md">
                  <p className="text-gray-900 text-lg lg:text-xl 2xl:text-2xl text-left">
                    What will we discuss?
                  </p>
                  <li className="mt-2 ml-1">
                    &#10003; ML concepts + applications
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Data analysis + pre-processing
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; 10+ machine learning projects
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Performance improvement strategies
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Model deployment techniques
                  </li>
                </div>

                <div className="tracking-wider border-2 text-sm sm:text-base 2xl:text-lg text-gray-600 p-4 sm:p-6 rounded-2xl shadow-md">
                  <p className="text-gray-900 text-lg lg:text-xl 2xl:text-2xl text-left">
                    What skills will you learn?
                  </p>
                  <li className="mt-2 ml-1">
                    &#10003; Model development + deployment
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Structured + Unstructured data
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Classification and regression
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Supervised and Unsupervised
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Numpy, Pandas, Scikit-Learn
                  </li>
                </div>

                <div className="tracking-wider border-2 text-sm sm:text-base 2xl:text-lg text-gray-600 p-4 sm:p-6 rounded-2xl shadow-md">
                  <p className="text-gray-900 text-lg lg:text-xl 2xl:text-2xl text-left">
                    Course prerequisite
                  </p>
                  <li className="mt-2 ml-1">
                    &#10003; Basic Python programming
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Basic problem solving skill
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Min 6 hours/week availability
                  </li>
                  <li className="mt-1 ml-1">&#10003; Curiosity to master ML</li>
                  <li className="mt-1 ml-1">
                    &#10003; Laptop with good internet
                  </li>
                </div>

                <div className="tracking-wider border-2 text-sm sm:text-base 2xl:text-lg text-gray-600 p-4 sm:p-6 rounded-2xl shadow-md">
                  <p className="text-gray-900 text-lg lg:text-xl 2xl:text-2xl text-left">
                    Course support
                  </p>
                  <li className="mt-2 ml-1">
                    &#10003; Unlimited doubt handling
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Three 1:1 mentorship sessions
                  </li>
                  <li className="mt-1 ml-1">&#10003; GitHub code support</li>
                  <li className="mt-1 ml-1">
                    &#10003; Weekly practice assignments
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Resume and profile building
                  </li>
                </div>
                <div className="tracking-wider border-2 text-sm sm:text-base 2xl:text-lg text-gray-600 p-4 sm:p-6 rounded-2xl shadow-md">
                  <p className="text-gray-900 text-lg lg:text-xl 2xl:text-2xl text-left">
                    Why join this course?
                  </p>
                  <li className="mt-2 ml-1">
                    &#10003; Interview centric course.
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Application-centric approach
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Reason-centric thought process
                  </li>
                  <li className="mt-1 ml-1">
                    &#10003; Guided + collaborative learning
                  </li>
                  <li className="mt-1 ml-1">&#10003; Delivered by experts.</li>
                </div>
                <div className=" flex flex-col border-2 tracking-wider items-start p-4 sm:p-6 rounded-2xl shadow-md">
                  <h2 className="text-lg lg:text-xl 2xl:text-2xl">
                    Course Instructor
                  </h2>
                  <h2 className="mt-2 text-lg lg:text-xl text-gray-600">
                    Ravish Kumar
                  </h2>

                  <Link
                    to="/ravish-kumar/"
                    className="px-4 mt-4 w-auto text-sm sm:text-base 2xl:text-lg tracking-wider shadow border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white py-1 rounded transition duration-150"
                  >
                    About Ravish Kumar
                  </Link>

                  <Link
                    to="/reviews-and-testimonials/"
                    className="px-4 mt-4 w-auto text-sm sm:text-base 2xl:text-lg tracking-wider shadow border border-new-purple bg-white text-new-purple hover:bg-new-purple hover:text-white py-1 rounded transition duration-150"
                  >
                    Explore Reviews
                  </Link>
                </div>
              </ul>

              <MlCourseForm />

              <MLFaq />
              <div className="mt-6">
                <SocialMedia />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
